import cn from 'classnames';
import Image from 'next/image';
import { getImage } from 'utils/helper';
import styles from './styles.module.scss';

const ScrollingGallery = ({ photos }) => {
  const imageUrls = (photos || []).map((asset) => getImage(asset));

  return (
    <div className={styles.container}>
      {Array.from({ length: 4 }).map((_, i) => (
        <div
          key={i}
          className={styles.scrollingEl}
        >
          {imageUrls?.map((photo, index) => (
            <div
              key={`photo-${index + 1}`}
              className={cn(styles.photo, styles[`photo-${index + 1}`])}
            >
              <Image
                src={photo.url}
                alt={photo.alt}
                layout="fill"
                loader={({ src }) => src}
                objectFit="cover"
                priority
                unoptimized
              />
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default ScrollingGallery;
